/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CampaignStatus } from '../models/CampaignStatus'
import type { ChartPrecision } from '../models/ChartPrecision'
import type { CreationType } from '../models/CreationType'
import type { Device } from '../models/Device'
import type { Error } from '../models/Error'
import type { GenericCampaignStatus } from '../models/GenericCampaignStatus'
import type { GenericCampaignType } from '../models/GenericCampaignType'
import type { NewsletterType } from '../models/NewsletterType'
import type { ProductType } from '../models/ProductType'
import type { PushStatType } from '../models/PushStatType'
import type { RequestArchiveAllCampaigns } from '../models/RequestArchiveAllCampaigns'
import type { RequestCampaignCopy } from '../models/RequestCampaignCopy'
import type { RequestCampaignPotentialCalculator } from '../models/RequestCampaignPotentialCalculator'
import type { RequestCreateDisplayCPCCampaign } from '../models/RequestCreateDisplayCPCCampaign'
import type { RequestCreateDisplayCPMCampaign } from '../models/RequestCreateDisplayCPMCampaign'
import type { RequestCreateDisplayVCPMCampaign } from '../models/RequestCreateDisplayVCPMCampaign'
import type { RequestCreateMailingProspectingCampaign } from '../models/RequestCreateMailingProspectingCampaign'
import type { RequestCreateMailingRetargetingCampaign } from '../models/RequestCreateMailingRetargetingCampaign'
import type { RequestCreateMailingStaticCampaign } from '../models/RequestCreateMailingStaticCampaign'
import type { RequestCreateProspectingCampaign } from '../models/RequestCreateProspectingCampaign'
import type { RequestCreatePublishersDisplayCPCCampaign } from '../models/RequestCreatePublishersDisplayCPCCampaign'
import type { RequestCreatePublishersDisplayVCPMCampaign } from '../models/RequestCreatePublishersDisplayVCPMCampaign'
import type { RequestCreatePublishersFlatFeeCampaign } from '../models/RequestCreatePublishersFlatFeeCampaign'
import type { RequestCreatePushCampaign } from '../models/RequestCreatePushCampaign'
import type { RequestCreateRetargetingCampaign } from '../models/RequestCreateRetargetingCampaign'
import type { RequestSendTestStaticMailing } from '../models/RequestSendTestStaticMailing'
import type { RequestTestPushCampaign } from '../models/RequestTestPushCampaign'
import type { RequestUpdateDisplayCPCCampaign } from '../models/RequestUpdateDisplayCPCCampaign'
import type { RequestUpdateDisplayCPMCampaign } from '../models/RequestUpdateDisplayCPMCampaign'
import type { RequestUpdateDisplayVCPMCampaign } from '../models/RequestUpdateDisplayVCPMCampaign'
import type { RequestUpdateMailingProspectingCampaign } from '../models/RequestUpdateMailingProspectingCampaign'
import type { RequestUpdateMailingRetargetingCampaign } from '../models/RequestUpdateMailingRetargetingCampaign'
import type { RequestUpdateMailingStaticCampaign } from '../models/RequestUpdateMailingStaticCampaign'
import type { RequestUpdateProspectingCampaign } from '../models/RequestUpdateProspectingCampaign'
import type { RequestUpdatePublishersDisplayCPCCampaign } from '../models/RequestUpdatePublishersDisplayCPCCampaign'
import type { RequestUpdatePublishersDisplayVCPMCampaign } from '../models/RequestUpdatePublishersDisplayVCPMCampaign'
import type { RequestUpdatePublishersFlatFeeCampaign } from '../models/RequestUpdatePublishersFlatFeeCampaign'
import type { RequestUpdatePushCampaign } from '../models/RequestUpdatePushCampaign'
import type { RequestUpdateRetargetingCampaign } from '../models/RequestUpdateRetargetingCampaign'
import type { RequestUploadMailingHeaderImage } from '../models/RequestUploadMailingHeaderImage'
import type { ResponseAvailableCampaignTypes } from '../models/ResponseAvailableCampaignTypes'
import type { ResponseCampaign } from '../models/ResponseCampaign'
import type { ResponseCampaignBalance } from '../models/ResponseCampaignBalance'
import type { ResponseCampaignHistory } from '../models/ResponseCampaignHistory'
import type { ResponseCampaignList } from '../models/ResponseCampaignList'
import type { ResponseCampaignPotentialCalculator } from '../models/ResponseCampaignPotentialCalculator'
import type { ResponseCampaignRunningConditions } from '../models/ResponseCampaignRunningConditions'
import type { ResponseCampaignStats } from '../models/ResponseCampaignStats'
import type { ResponseCampaignStatsSummary } from '../models/ResponseCampaignStatsSummary'
import type { ResponseCampaignSummary } from '../models/ResponseCampaignSummary'
import type { ResponseCampaignToArchive } from '../models/ResponseCampaignToArchive'
import type { ResponseChartData } from '../models/ResponseChartData'
import type { ResponseEntityCreated } from '../models/ResponseEntityCreated'
import type { ResponseGenericCampaign } from '../models/ResponseGenericCampaign'
import type { ResponsePushChartData } from '../models/ResponsePushChartData'
import type { ResponseSimpleCampaignListItem } from '../models/ResponseSimpleCampaignListItem'
import type { ResponseUploadMailingHeaderImage } from '../models/ResponseUploadMailingHeaderImage'
import type { StatType } from '../models/StatType'
import type { StatusFilter } from '../models/StatusFilter'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class CampaignService {
  /**
   * Tworzy nową kampanię typu ROAS
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createRoasCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateRetargetingCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/roas-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię typu ROAS
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateRoasCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateRetargetingCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/roas-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię mailingową retargetingową
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static addMailingCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateMailingRetargetingCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-mailing-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię mailingową retargetingową
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static modifyMailingCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateMailingRetargetingCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-mailing-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Header upload
   * @returns ResponseUploadMailingHeaderImage Header upload
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static uploadHeaderImage({
    shopId,
    formData,
  }: {
    shopId: number
    /**
     * Obraz do wgrania
     */
    formData?: RequestUploadMailingHeaderImage
  }): CancelablePromise<ResponseUploadMailingHeaderImage | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-mailing-campaign/header-image/{shopId}',
      path: {
        shopId: shopId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    })
  }

  /**
   * Tworzy nową kampanię cpc
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createProspectingCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateProspectingCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-cpc-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię cpc
   *
   * @returns Error unexpected error
   * @returns any kampania zmodyfikowana
   * @throws ApiError
   */
  public static updateProspectingCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateProspectingCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-cpc-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię typu Display VCPM
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createDisplayVcpmCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateDisplayVCPMCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-display-vcpm-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię typu Display VCPM dla wydawcy
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createPublishersDisplayVcpmCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreatePublishersDisplayVCPMCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-publishers-display-vcpm-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię typu Display CPC dla wydawcy
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createPublishersDisplayCpcCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreatePublishersDisplayCPCCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-publishers-display-cpc-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię typu Display CPC
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createDisplayCpcCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateDisplayCPCCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-display-cpc-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię typu Display CPC
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createDisplayCpmCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateDisplayCPMCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-display-cpm-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię typu Display VCPM
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateDisplayVcpmCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateDisplayVCPMCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-display-vcpm-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię typu Display VCPM dla wydawcy
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updatePublishersDisplayVcpmCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdatePublishersDisplayVCPMCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-publishers-display-vcpm-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię typu Display CPC dla wydawcy
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updatePublishersDisplayCpcCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdatePublishersDisplayCPCCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-publishers-display-cpc-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię typu Display CPC
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateDisplayCpcCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateDisplayCPCCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-display-cpc-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię typu Display CPM
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static updateDisplayCpmCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateDisplayCPMCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-display-cpm-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy kampanię mailingową prospectingową
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static addMailingProspectingCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateMailingProspectingCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-mailing-prospecting-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię mailingową prospectingową
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static modifyMailingProspectingCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateMailingProspectingCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-mailing-prospecting-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy kampanię mailingową statyczną
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static addMailingStaticCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateMailingStaticCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-mailing-static-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię mailingową statyczną
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static modifyMailingStaticCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateMailingStaticCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-mailing-static-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy kampanię mailingową statyczną CPM
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static addMailingStaticCpmCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateMailingStaticCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-mailing-static-cpm-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię mailingową statyczną CPM
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static modifyMailingStaticCpmCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdateMailingStaticCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-mailing-static-cpm-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy kampanię flat fee dla wydawców
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static addPublishersFlatFeeCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreatePublishersFlatFeeCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-publishers-flat-fee-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię flat fee dla wydawców
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static modifyPublishersFlatFeeCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdatePublishersFlatFeeCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-publishers-flat-fee-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy kampanię push
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static addPushCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreatePushCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-push-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje kampanię push
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static modifyPushCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody: RequestUpdatePushCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/mc-push-campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Wysyła test kampanii push
   *
   * @returns Error unexpected error
   * @returns any dane zapisane
   * @throws ApiError
   */
  public static testPushCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestTestPushCampaign
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mc-push-campaign/test',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera dane statystyczne kampanii
   * @returns ResponseChartData podsumowanie statystyk
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getTimeSeriesStats({
    metrics,
    shopId,
    dateFrom,
    campaignIds,
    creationsIds,
    dateTo,
    devices,
    precision,
  }: {
    /**
     * wybierane typy statystyk
     */
    metrics: Array<StatType>
    shopId: number
    /**
     * data od której będą statystyki kampanii
     */
    dateFrom: string
    /**
     * identyfikatory kampanii
     */
    campaignIds?: Array<number>
    /**
     * identyfikatory kreacji reklamowej
     */
    creationsIds?: Array<number>
    /**
     * data do której będą statystyki kampanii
     */
    dateTo?: string
    /**
     * filtr na urządzenie
     */
    devices?: Array<Device>
    /**
     * ziarno agregacji danych
     */
    precision?: ChartPrecision
  }): CancelablePromise<ResponseChartData | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/time-series-stats',
      query: {
        metrics: metrics,
        campaignIds: campaignIds,
        creationsIds: creationsIds,
        shopId: shopId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        devices: devices,
        precision: precision,
      },
    })
  }

  /**
   * Pobiera dane statystyczne kampanii push
   * @returns ResponsePushChartData podsumowanie statystyk
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPushTimeSeriesStats({
    metrics,
    shopId,
    dateFrom,
    campaignIds,
    dateTo,
    precision,
  }: {
    /**
     * wybierane typy statystyk dla pushy
     */
    metrics: Array<PushStatType>
    shopId: number
    /**
     * data od której będą statystyki kampanii
     */
    dateFrom: string
    /**
     * identyfikatory kampanii
     */
    campaignIds?: Array<number>
    /**
     * data do której będą statystyki kampanii
     */
    dateTo?: string
    /**
     * ziarno agregacji danych
     */
    precision?: ChartPrecision
  }): CancelablePromise<ResponsePushChartData | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/push-time-series-stats',
      query: {
        metrics: metrics,
        campaignIds: campaignIds,
        shopId: shopId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        precision: precision,
      },
    })
  }

  /**
   * zwraca listę kampanii przypisanych do sklepu
   *
   * @returns ResponseCampaignList lista kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignList({
    shopId,
    page,
    limit,
    creationType,
    onlyArchive,
    sortBy = 'createDate',
    sortDir,
    nameSearch,
    status,
    type,
    dateFrom,
    dateTo,
    relativeRange,
  }: {
    shopId: number
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * typ kreacji reklamowej
     */
    creationType?: CreationType
    onlyArchive?: boolean
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?:
      | 'name'
      | 'cr'
      | 'income'
      | 'clicks'
      | 'transactions'
      | 'aov'
      | 'cost'
      | 'roas'
      | 'cos'
      | 'vimp'
      | 'views'
      | 'ctr'
      | 'vctr'
      | 'vrate'
      | 'ecpc'
      | 'openrate'
      | 'octr'
      | 'open'
      | 'delivery'
      | 'createDate'
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * filtr na nazwę kampanii
     */
    nameSearch?: string
    /**
     * filtr na status kampanii
     */
    status?: GenericCampaignStatus | CampaignStatus
    /**
     * filtr na typ
     */
    type?: GenericCampaignType | ProductType
    /**
     * data od której będą statystyki kampanii
     */
    dateFrom?: string
    /**
     * data do której będą statystyki kampanii
     */
    dateTo?: string
    /**
     * data do której będą statystyki kampanii
     */
    relativeRange?:
      | 'THIS_MONTH'
      | 'TODAY'
      | 'YESTERDAY'
      | 'THIS_WEEK'
      | 'LAST_7_DAYS'
      | 'LAST_30_DAYS'
      | 'THIS_YEAR'
  }): CancelablePromise<ResponseCampaignList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign',
      query: {
        creationType: creationType,
        shopId: shopId,
        onlyArchive: onlyArchive,
        sortBy: sortBy,
        sortDir: sortDir,
        page: page,
        limit: limit,
        nameSearch: nameSearch,
        status: status,
        type: type,
        dateFrom: dateFrom,
        dateTo: dateTo,
        relativeRange: relativeRange,
      },
    })
  }

  /**
   * Pobiera podsumowanie statystyk kampanii
   * @returns ResponseCampaignStatsSummary lista kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignStatsSummary({
    shopId,
    dateFrom,
    campaignIds,
    dateTo,
    creationType,
    nameSearch,
    fetchArchived,
    status,
    type,
  }: {
    shopId: number
    /**
     * data od której będą statystyki kampanii
     */
    dateFrom: string
    /**
     * identyfikatory kampanii
     */
    campaignIds?: Array<number>
    /**
     * data do której będą statystyki kampanii
     */
    dateTo?: string
    /**
     * typ kreacji reklamowej
     */
    creationType?: CreationType
    /**
     * filtr na nazwę kampanii
     */
    nameSearch?: string
    /**
     * czy pobierać razem z archiwalnymi
     */
    fetchArchived?: boolean
    /**
     * filtr na status kampanii
     */
    status?: StatusFilter
    /**
     * filtr na typ
     */
    type?: GenericCampaignType | ProductType
  }): CancelablePromise<ResponseCampaignStatsSummary | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/stats-summary',
      query: {
        campaignIds: campaignIds,
        shopId: shopId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        creationType: creationType,
        nameSearch: nameSearch,
        fetchArchived: fetchArchived,
        status: status,
        type: type,
      },
    })
  }

  /**
   * zwraca listę kampanii przypisanych do sklepu
   *
   * @returns ResponseCampaignList lista kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignListWithStats({
    shopId,
    page,
    limit,
    creationType,
    sortBy = 'createDate',
    sortDir,
    nameSearch,
    fetchArchived,
    status,
    type,
    excludeType,
    dateFrom,
    dateTo,
    relativeRange,
  }: {
    shopId: number
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * typ kreacji reklamowej
     */
    creationType?: CreationType
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?:
      | 'name'
      | 'CR'
      | 'INCOME'
      | 'CLICKS'
      | 'TRANSACTIONS'
      | 'AOV'
      | 'COST'
      | 'ROAS'
      | 'COS'
      | 'VIMP'
      | 'VIEWS'
      | 'CTR'
      | 'VCTR'
      | 'VRATE'
      | 'ECPC'
      | 'OPENRATE'
      | 'OCTR'
      | 'OPEN'
      | 'DELIVERY'
      | 'createDate'
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * filtr na nazwę kampanii
     */
    nameSearch?: string
    /**
     * czy pobierać razem z archiwalnymi
     */
    fetchArchived?: boolean
    /**
     * filtr na status kampanii
     */
    status?: StatusFilter
    /**
     * filtr na typ
     */
    type?: GenericCampaignType | ProductType | NewsletterType
    /**
     * filtr na wykluczenie typów
     */
    excludeType?: GenericCampaignType | ProductType | NewsletterType
    /**
     * data od której będą statystyki kampanii
     */
    dateFrom?: string
    /**
     * data do której będą statystyki kampanii
     */
    dateTo?: string
    /**
     * data do której będą statystyki kampanii
     */
    relativeRange?:
      | 'THIS_MONTH'
      | 'TODAY'
      | 'YESTERDAY'
      | 'THIS_WEEK'
      | 'LAST_7_DAYS'
      | 'LAST_30_DAYS'
      | 'THIS_YEAR'
  }): CancelablePromise<ResponseCampaignList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign-with-stats',
      query: {
        creationType: creationType,
        shopId: shopId,
        sortBy: sortBy,
        sortDir: sortDir,
        page: page,
        limit: limit,
        nameSearch: nameSearch,
        fetchArchived: fetchArchived,
        status: status,
        type: type,
        excludeType: excludeType,
        dateFrom: dateFrom,
        dateTo: dateTo,
        relativeRange: relativeRange,
      },
    })
  }

  /**
   * zwraca informacje o wybranej kampanii
   *
   * @returns any infomacje o kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<(ResponseCampaign | ResponseGenericCampaign) | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * zwraca listę warunków opisujących status działąnia kampani
   *
   * @returns ResponseCampaignRunningConditions tablica warunków
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static campaignRunningConditions({
    campaignId,
    modifyType,
  }: {
    campaignId: number
    modifyType: 'CREATE' | 'UPDATE' | 'RUN'
  }): CancelablePromise<ResponseCampaignRunningConditions | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/running-conditions/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      query: {
        modifyType: modifyType,
      },
    })
  }

  /**
   * wstrzymuje kampanię o wybranym ID
   *
   * @returns Error unexpected error
   * @returns any kampania zatrzymana
   * @throws ApiError
   */
  public static stopCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/campaign/{campaignId}/stop',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * ponownie uruchamia zapauzowaną kampanię o wybranym ID
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static startCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/campaign/{campaignId}/start',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Zwraca saldo dla danej kampanii (pobrane z bankiera)
   *
   * @returns ResponseCampaignBalance wartość salda
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignBalance({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<ResponseCampaignBalance | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/balance',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Zwraca historię edycji kampanii
   *
   * @returns ResponseCampaignHistory Historia kampanii
   * @throws ApiError
   */
  public static getCampaignHistory({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Array<ResponseCampaignHistory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/history',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Zwraca podgląd kampanii dowolnego typu
   *
   * @returns string Podgląd kampanii
   * @throws ApiError
   */
  public static getCampaignPreview({
    campaignId,
    creationId,
    creationVariant,
    creationFormat,
  }: {
    campaignId: number
    /**
     * identyfikator kreacji reklamowej
     */
    creationId?: number
    creationVariant?: 'txt' | 'txt_gfx' | 'native_box' | 'related_content'
    /**
     * format kreacji zaisany jako SZEROKOSCxWYSOKOSC (120x600)
     */
    creationFormat?: string
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/preview',
      path: {
        campaignId: campaignId,
      },
      query: {
        creationId: creationId,
        creationVariant: creationVariant,
        creationFormat: creationFormat,
      },
    })
  }

  /**
   * Zwraca linki do podglądu kampanii na serwisie
   *
   * @returns any Podgląd kampanii
   * @throws ApiError
   */
  public static getFeedCampaignPreviewUrls({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Array<any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/feed-preview-urls',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Zraca podsumowanie kampani (dane budżetowe)
   *
   * @returns any podumowanie danych budżetowych
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignSummary({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<ResponseCampaignSummary | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/summary',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * zwraca szczegółowe statystki dla kampanii z podziałem na device, a dla kampanii display również z podziałem
   * na kreacje, a dla natywek równiez z podziałem na warianty
   *
   * @returns ResponseCampaignStats lista statystyk per device, per kreacja
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignStatsDetails({
    campaignId,
    dateFrom,
    sortBy = 'createDate',
    sortDir,
    dateTo,
  }: {
    campaignId: number
    /**
     * data od której będą statystyki
     */
    dateFrom: string
    /**
     * pole po którym będą sortowane kreacje w statystykach
     */
    sortBy?:
      | 'name'
      | 'CR'
      | 'INCOME'
      | 'CLICKS'
      | 'TRANSACTIONS'
      | 'AOV'
      | 'COST'
      | 'ROAS'
      | 'COS'
      | 'VIMP'
      | 'VIEWS'
      | 'CTR'
      | 'VCTR'
      | 'VRATE'
      | 'ECPC'
      | 'OPENRATE'
      | 'OCTR'
      | 'OPEN'
      | 'DELIVERY'
      | 'createDate'
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * data do której będą statystyki
     */
    dateTo?: string
  }): CancelablePromise<ResponseCampaignStats | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/stats-details',
      path: {
        campaignId: campaignId,
      },
      query: {
        sortBy: sortBy,
        sortDir: sortDir,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    })
  }

  /**
   * Kopiuje kampanie
   *
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static copyCampaign({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kampanii
     */
    requestBody?: RequestCampaignCopy
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/campaign/{campaignId}/copy',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Wysyła testowy mailing statyczny
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static sendTestStaticMailing({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane mailingu statycznego
     */
    requestBody: RequestSendTestStaticMailing
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/campaign/{campaignId}/send-test-static-mailing',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Archiwizuje kampanię
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static archiveCampaigns({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/campaign/{campaignId}/archive',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Archiwizuje wszystkie kampanię, które zakończyły się X dni temu
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static archiveAllCampaigns({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane kampanii
     */
    requestBody?: RequestArchiveAllCampaigns
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/campaign/{shopId}/archive-all',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * zwraca dostępne typy kampani dla sklepu
   *
   * @returns ResponseAvailableCampaignTypes dostępne typy kampani dla sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAvailableCampaignTypes({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseAvailableCampaignTypes | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign-types/{shopId}',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * zwraca uproszczoną listę kampanii przypisanych do sklepu
   *
   * @returns ResponseSimpleCampaignListItem lista kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getSimpleCampaignList({
    shopId,
    onlyArchive,
    type,
  }: {
    shopId: number
    onlyArchive?: boolean
    /**
     * filtr na typ
     */
    type?: GenericCampaignType | ProductType | NewsletterType
  }): CancelablePromise<Array<ResponseSimpleCampaignListItem> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/simple-campaign-list/{shopId}',
      path: {
        shopId: shopId,
      },
      query: {
        onlyArchive: onlyArchive,
        type: type,
      },
    })
  }

  /**
   * zwraca listę kampanii, które zostaną wkrótce zarchiwizowane
   *
   * @returns ResponseCampaignToArchive lista kampanii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCampaignsToArchive({
    shopId,
    days,
  }: {
    shopId: number
    days: number
  }): CancelablePromise<Array<ResponseCampaignToArchive> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaigns-to-archive/{shopId}',
      path: {
        shopId: shopId,
      },
      query: {
        days: days,
      },
    })
  }

  /**
   * @returns ResponseCampaignPotentialCalculator kalkulator zasięgów
   * @throws ApiError
   */
  public static getCampaignPotential({
    requestBody,
  }: {
    /**
     * targetowanie
     */
    requestBody: RequestCampaignPotentialCalculator
  }): CancelablePromise<Array<ResponseCampaignPotentialCalculator>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/campaign/potential-calculator',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
